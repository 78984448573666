export default {
  grayLight: '#f4f4f4',
  gray200: '#e8e8e8',
  gray: '#616E81',
  grayDark: '#323232',
  blueLight: '#009ded',
  blue: '#305CFF',
  blueDark: '#0A2880',
  red: '#FF2C00',
  redMenu: '#FF2C00',
  yellow: '#FFCD00',
  purple: '#6235CD',
  purpleLight:'#8A69E0',
  white:'#fff',
};
